do (context = this) ->
  "use strict"
  # shortcuts
  pi = context.pi
  utils = pi.utils

  pi.tools ||= {}

  class pi.tools.ScriptLoader
    @pending: {} 
    
    @load: (id, path, options={}) ->
        
      if id && @pending[id]
        return @pending[id]

      _promise = new Promise( 
        (resolve, reject) ->
          nod = pi.Nod.create('script')
          nod.attr('type', 'text/javascript')
          
          if options.attrs?
            nod.attrs options.attrs
          
          nod.node.src = path
          
          flag = false
          
          nod.node.onload = nod.node.onreadystatechange = ->
            if !flag && (!@readyState || @readyState is 'complete')
              flag = true
              resolve()

          nod.node.onerror = reject

          pi.Nod.body.append nod
      )

      if id?
        @pending[id] = _promise
      else
        _promise