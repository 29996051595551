do (context = this) ->
  "use strict"
  # shortcuts
  pi = context.pi
  utils = pi.utils

  class pi.HelpCallout extends pi.Base
    postinitialize: ->
      @root_handler = => @deactivate()
      super()

    activate: (data) ->
      @on 'click', @click_handler()
      pi.Nod.root.one 'click', @root_handler
      super()

    click_handler: ->
      @_click_handler ||= (e) =>
        unless utils.clickable(e.target.node)
          e.cancel()

    deactivate: ->
      @off 'click', @click_handler()
      pi.Nod.root.off 'click', @root_handler
      super()
