pi.Nod.root.ready ->
  if pi.tools?.ActivityWatcher? && !pi.app.disable_activity?
    pi.app.activity_watcher = new pi.tools.ActivityWatcher()

    unless window.ACTIVITY_DIMNESS is false
      pi.app.activity_watcher.on 'active', (e) ->
        if e.data
          pi.Nod.body.removeClass 'no-activity'
        else
          pi.Nod.body.addClass 'no-activity'

    after 0, ->
      pi.app.activity_watcher.start()

    if pi.app.user?
      pi.app.visitor = new pi.tools.Visitor(pi.utils.merge((window.TRACK_PARAMS||{}), {user_id: pi.app.user?.id, account_id: pi.app.user?.account_id}))
      after 0, ->
        pi.app.visitor.init()