import "./user_activity.coffee"

# add auth_token for xhr requests
pi.Net.headers['X-CSRF-Token'] = window.AUTH_TOKEN

pi.Net.method_override = true

pi.Nod.root.ready ->
  _start_ts = +(new Date())

  # add confirmator
  if pi.tools?.Confirmator?
      pi.app.confirmator = pi.tools.Confirmator

  pi.app.initialize()
  pi.__init_time__ = (+(new Date()) - _start_ts)

  messages = pi.app.flash_messages

  if messages?
    pi.Nod.win.one 'load', ->
      # show flash messages
      pi.app.view.success?.show(messages.success, 4000) if messages.success
      pi.app.view.error?.show(messages.error, 4000) if messages.error
